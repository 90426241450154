import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

// Interfaces
import { User, Role, Team, Policy } from './user-management-interfaces';

// Services
import { ParamsService } from '../core/services/params.service';

@Injectable()
export class UserManagementService {

  constructor(
    private http: HttpClient,
    private paramsService: ParamsService) {
  }

  getUsers(paramOptions?): Observable<any> {
    const params = paramOptions ? this.paramsService.getQueryParams(paramOptions) : 'pageSize=-1';
    return this.http.get(`user?isTeamAssociate=true&${params.replace(/\+/g, '%2B')}`)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getRoles(paramOptions?): Observable<any> {
    const params = paramOptions ? this.paramsService.getQueryParams(paramOptions) : 'pageSize=-1';
    return this.http.get(`role?${params.replace(/\+/g, '%2B')}`)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getTeams(paramOptions?): Observable<any> {
    const params = paramOptions ? this.paramsService.getQueryParams(paramOptions) : 'pageSize=-1';
    return this.http.get(`team/list?${params.replace(/\+/g, '%2B')}`)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  getTeamServiceableRegions(teamId, paramOptions?): Observable<any> {
    const params = paramOptions ? this.paramsService.getQueryParams(paramOptions) : 'pageSize=-1';
    return this.http.get(`team/serviceable-regions/${teamId}?${params.replace(/\+/g, '%2B')}`)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  addNewUser(newUser): Observable<User> {
    return this.http.post(`user`, newUser)
      .pipe(
        map((response: any) => {
          return <User>response.data.users;
        })
      );
  }

  sendConfirmation(payload): Observable<User> {
    return this.http.post(`user/passwordResent`, payload)
      .pipe(
        map((response: any) => {
          return <User>response.data.users;
        })
      );
  }

  getPolicies(): Observable<Array<Policy>> {
    return this.http.get(`policy`, { params: { 'integration': 'true' } })
      .pipe(
        map((response: any) => {
          return <Array<Policy>>response.data.policies;
        })
      );
  }

  addNewRole(role): Observable<Role> {
    return this.http.post(`role`, role)
      .pipe(
        map((response: any) => {
          return <Role>response;
        })
      );
  }

  editNewRole(role): Observable<Role> {
    return this.http.post(`role`, role)
      .pipe(
        map((response: any) => {
          return <Role>response;
        })
      );
  }

  deleteRole(roleId): Observable<Role> {
    return this.http.delete(`role/${roleId}`)
      .pipe(
        map((response: any) => {
          return <Role>response;
        })
      );
  }

  deleteTeam(teamId): Observable<Team> {
    return this.http.delete(`team/${teamId}`)
      .pipe(
        map((response: any) => {
          return <Team>response;
        })
      );
  }

  editUser(user): Observable<User> {
    return this.http.post(`user/`, user)
      .pipe(
        map((response: any) => {
          return <User>response.data.users;
        })
      );
  }

  deleteUser(UserId): Observable<User> {
    return this.http.delete(`user/${UserId}`)
      .pipe(
        map((response: any) => {
          return <User>response;

        })
      );
  }

  deactivateUser(userId: string) {
    return this.http.put(`user/${userId}/deactivate`, {});
  }

  activateUser(userId: string) {
    return this.http.put(`user/${userId}/activate`, {});
  }

  addNewTeam(newTeam): Observable<Team> {
    return this.http.post(`team`, newTeam)
      .pipe(
        map((response: any) => {
          return response.data.team;
        })

      );
  }

  updateTeam(team, teamId: string): Observable<any> {
    return this.http.put(`team/${teamId}`, team)
      .pipe(
        map((response: any) => {
          return response.data.team;
        })

      );
  }

  getInspection(paramOptions): Observable<any> {
    console.log('paramOptions', paramOptions);
    const params = paramOptions ? this.paramsService.getQueryParams(paramOptions) : 'pageSize=-1';
    return this.http.get(`action?${params.replace(/\+/g, '%2B')}`)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  addAttachment(formdata): Observable<any> {
    console.log('attachment', formdata);
    return this.http.post(`attachment`, formdata)
      .pipe(
        map((response: any) => {
          return response.data.attachments;
        })
      );

  }

  getConstraintUsers(paramOptions?): Observable<any> {
    const params = paramOptions ? this.paramsService.getQueryParams(paramOptions) : 'pageSize=10';
    return this.http.get(`user?isActive=true&isDetailed=false&${params.replace(/\+/g, '%2B')}`)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}
