import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ParamsService } from 'src/app/core/services/params.service';
import { FollowUpComment } from './inspections-interfaces';


@Injectable({
  providedIn: 'root'
})
export class InspectionService {

  constructor(
    private http: HttpClient,
    private paramsService: ParamsService
  ) { }

  getInspectionList(paramOptions?): Observable<any> {
    const params = paramOptions ? this.paramsService.getQueryParams(paramOptions) : 'pageSize=-1';
    return this.http.get(`inspection?${params.replace(/\+/g, '%2B')}`)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getInspectionDetails(inspectionID): Observable<any> {
    return this.http.get(`inspection/detail/${inspectionID}`)
      .pipe(
        map((response: any) => {
          return response.data.action;
        })
      );
  }
  getFollowUps(inspectionID): Observable<any> {
    return this.http.get(`follow-up/type/${inspectionID}`)
      .pipe(
        map((response: any) => {
          console.log(response, 'followups');
          return response.data;
        })
      );
  }
  addAttachment(files, data): Observable<any> {
    return this.http.post(`attachment/`, files, data)
      .pipe(
        map((response: any) => {
          return response.data.actions;
        })
      );

  }
  addComments(comment: FollowUpComment): Observable<any> {
    return this.http.post(`comment-followup`, comment)
      .pipe(
        map((response: any) => {
          return response.data.commentFollowup;
        })
      );
  }
  upsertFollowUp(followUp): Observable<any> {
    return this.http.post(`followup`, followUp)
      .pipe(
        map((response: any) => {
          return response.data;
        })
      );
  }
  getClientData() {
    return this.http.get('user/me', { params: { 'integration': 'true' } })
      .pipe(
        map((response: any) => {
          return response.data.user;
        })
      );
  }
  getFollowUpsData(paramOptions?): Observable<any> {
    const _types = JSON.stringify(paramOptions.status);
    paramOptions.status = _types;
    const params = paramOptions ? this.paramsService.getQueryParams(paramOptions) : 'pageSize=-1';
    return this.http.get(`follow-up?${params.replace(/\+/g, '%2B')}`)
      .pipe(
        map((response: any) => {
          return response.data;
        })
      );
  }
  getActionableSites(paramOptions?) {
    const params = paramOptions ? this.paramsService.getQueryParams(paramOptions) : 'pageSize=-1';
    return this.http.get(`integration/inspection/actionable-site?${params.replace(/\+/g, '%2B')}`)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}
